import React from "react";
import PageTitle from "website/components/Common/PageTitle";
import { Helmet } from "react-helmet";

const SecurityPolicy = () => {
  return (
    <><Helmet>
      <title>MCine | Security Policy</title>
    </Helmet>
    <div>
    <div className="bg-slate pb-5" style={{ minHeight: "35vh" }}>
      <div className="wrapper">
        <ul className="breadcrumb subtitle mx-1 pb-4">
          <li>
            <a href="/">Home</a>
          </li>
          <li>Security Policy</li>
        </ul>
          <p className="text-white text-justify" style={{ whiteSpace: "pre-line" }}>
            MONT IDA ENTERTAINMENTS LTD is committed to ensuring the security of your information. This Security Policy outlines the measures we take to protect the data collected through our website, https://www.mcine.mu (hereinafter, "us", "we", or "https://www.mcine.mu"), and our commitment to maintaining the integrity, confidentiality, and security of your personal information.
          </p>
          <h4 className="text-primary mb-4 text-justify">Data Protection Measures</h4>
          <p className="text-white text-justify">
            <strong>1. Encryption</strong>
            <br />
            We use SSL (Secure Socket Layer) encryption technology to ensure that data transmitted between your browser and our servers is secure. This helps protect your personal information from unauthorized access during transmission.
          </p>
          <p className="text-white text-justify">
            <strong>2. Secure Access</strong>
            <br />
            Access to your personal information is restricted to authorized personnel who need the information to perform their job duties. These individuals are bound by confidentiality agreements and are trained on the importance of protecting personal data.
          </p>
          <p className="text-white text-justify">
            <strong>3. Data Storage</strong>
            <br />
            We store personal information on secure servers that are protected by firewalls and other advanced security technologies. These measures help prevent unauthorized access, use, or disclosure of your data.
          </p>
          <p className="text-white text-justify">
            <strong>4. Regular Security Audits</strong>
            <br />
            We conduct regular security audits and assessments of our systems and processes to identify and address potential vulnerabilities. This includes reviewing access logs, monitoring for suspicious activities, and updating security protocols as needed.
          </p>
          <p className="text-white text-justify">
            <strong>5. User Authentication</strong>
            <br />
            Users are required to create strong passwords for their accounts, and we encourage the use of two-factor authentication (2FA) for added security. Passwords are stored in an encrypted format and are not accessible by our staff.
          </p>
          <p className="text-white text-justify">
            <strong>6. Software Updates</strong>
            <br />
            We regularly update our software, including web servers and applications, to protect against the latest security threats. This helps ensure that our systems remain secure and that vulnerabilities are promptly addressed.
          </p>
          <h4 className="text-primary mb-4 text-justify">Incident Response</h4>
          <p className="text-white text-justify">
            <strong>1. Monitoring and Detection</strong>
            <br />
            We continuously monitor our systems for potential security breaches and unauthorized access attempts. Automated tools and manual processes are in place to detect and respond to security incidents.
          </p>
          <p className="text-white text-justify">
            <strong>2. Incident Management</strong>
            <br />
            In the event of a security breach, we have an incident response plan in place to quickly assess and mitigate the impact. This includes identifying the source of the breach, containing the threat, and restoring affected systems.
          </p>
          <p className="text-white text-justify">
            <strong>3. Notification</strong>
            <br />
            If a security breach results in the unauthorized access to your personal information, we will notify you as soon as possible. We will provide details about the breach, the information affected, and the steps we are taking to address the issue.
          </p>
          <h4 className="text-primary mb-4 text-justify">User Responsibilities</h4>
          <p className="text-white text-justify">
            <strong>1. Account Security</strong>
            <br />
            Users are responsible for maintaining the security of their account credentials. This includes choosing strong passwords, enabling two-factor authentication, and not sharing login information with others.
          </p>
          <p className="text-white text-justify">
            <strong>2. Phishing and Fraud Prevention</strong>
            <br />
            Users should be cautious of phishing attempts and fraudulent communications that may appear to come from MONT IDA ENTERTAINMENTS LTD. We will never ask for your password or sensitive information via email or phone. If you suspect any fraudulent activity, please contact us immediately.
          </p>
          <h4 className="text-primary mb-4 text-justify">Third-Party Services</h4>
          <p className="text-white text-justify">
            <strong>1. External Links</strong>
            <br />
            Our website may contain links to external sites that are not operated by us. We are not responsible for the security practices or content of these third-party sites. We encourage you to review the security policies of any external sites you visit.
          </p>
          <p className="text-white text-justify">
            <strong>2. Service Providers</strong>
            <br />
            We work with third-party service providers to help operate our website and provide services to you. These providers are contractually obligated to implement security measures to protect your personal information and are prohibited from using your data for any other purposes.
          </p>
          <h4 className="text-primary mb-4 text-justify">Policy Updates</h4>
          <p className="text-white text-justify">
            We may update this Security Policy from time to time to reflect changes in our practices or legal requirements. We encourage you to review this page periodically for any updates. Your continued use of our website after any changes to this Security Policy will constitute your acceptance of such changes.
          </p>
          <h4 className="text-primary mb-4 text-justify">Contact Information</h4>
          <p className="text-white text-justify">
            If you have any questions about this Security Policy, please contact us via email at contact@mcine.mu or phone at +230 464 1900.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default SecurityPolicy;
