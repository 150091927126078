import React from "react";
import PageTitle from "website/components/Common/PageTitle";
import { Helmet } from "react-helmet";

const TermsOfUse = () => {
    return (
      <><Helmet>
        <title>MCine | Terms of Use</title>
      </Helmet>
      <div>
        <div className="bg-slate pb-5" style={{ minHeight: "35vh" }}>
          <div className="wrapper">
            <ul className="breadcrumb subtitle mx-1 pb-4">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Terms of Use</li>
            </ul>
            <h4 className="text-primary mb-4 text-justify">Welcome to MCine (www.mcine.mu)!</h4>
            <p className="text-white text-justify">
              These terms and conditions outline the rules and regulations for the use of MONT IDA ENTERTAINMENTS LTD's Website located at www.mcine.mu. By accessing this website we assume you accept these terms and conditions. Do not continue to use MCine if you do not agree to take all of the terms and conditions stated on this page.
            </p>
            <h4 className="text-primary mb-4 text-justify">Definitions</h4>
            <p className="text-white text-justify">
              <ul>
                <li>"Client" "You" and "Your" refers to you the person logging on this website and compliant with the Company's terms and conditions.</li><li>"The Company" "Ourselves" "We" "Our" and "Us" refers to our Company MONT IDA ENTERTAINMENTS LTD.</li><li>"Party" "Parties" or "Us" refers to both the Client and ourselves.</li></ul>
              All terms refer to the offer acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client's needs in respect of the provision of the Company's stated services in accordance with and subject to the prevailing law of Mauritius. Any use of the above terminology or other words in the singular plural capitalization and/or he/she or they are taken as interchangeable and therefore as referring to the same.
            </p>
            <h4 className="text-primary mb-4 text-justify">Website Visitors</h4>
            <p className="text-white text-justify">
              We employ the use of cookies. By accessing MCine you agreed to use cookies in agreement with MONT IDA ENTERTAINMENTS LTD's Privacy Policy. Most interactive websites use cookies to let us retrieve the user's details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.
            </p>
            <h4 className="text-primary mb-4 text-justify">License</h4>
            <p className="text-white text-justify">
              Unless otherwise stated MONT IDA ENTERTAINMENTS LTD and/or its licensors own the intellectual property rights for all material on MCine. All intellectual property rights are reserved. You may access this from MCine for your own personal use subjected to restrictions set in these terms and conditions.
            </p>
            <p className="text-white text-justify">
              You must not:  
            </p>
            <ul className="text-white text-justify">
              <li>Republish material from MCine</li>
              <li>Sell rent or sub-license material from MCine</li>
              <li>Reproduce duplicate or copy material from MCine</li>
              <li>Redistribute content from MCine</li>
            </ul>
            <p className="text-white text-justify">This Agreement shall begin on the date hereof.</p>
            <h4 className="text-primary mb-4 text-justify">User-Generated Content</h4>
            <p className="text-white text-justify">
              Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. MONT IDA ENTERTAINMENTS LTD does not filter edit publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of MONT IDA ENTERTAINMENTS LTD its agents and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws MONT IDA ENTERTAINMENTS LTD shall not be liable for the Comments or for any liability damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.<br></br><br></br>MONT IDA ENTERTAINMENTS LTD reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate offensive or causes a breach of these Terms and Conditions.
            </p>
            <p className="text-white mb-4 text-justify">You warrant and represent that:</p>
            <ul className="text-white text-justify">
              <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</li>
              <li>The Comments do not invade any intellectual property right including without limitation copyright patent or trademark of any third party;</li>
              <li>The Comments do not contain any defamatory libelous offensive indecent or otherwise unlawful material which is an invasion of privacy;</li>
              <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
            </ul>
            <p className="text-white text-justify">
              You hereby grant MONT IDA ENTERTAINMENTS LTD a non-exclusive license to use reproduce edit and authorize others to use reproduce and edit any of your Comments in any and all forms formats or media.
            </p>
            <h4 className="text-primary mb-4 text-justify">Hyperlinking to our Content</h4>
            <p className="text-white text-justify">
              The following organizations may link to our Website without prior written approval:
            </p>
            <ul className="text-white text-justify">
              <li>Government agencies;</li>
              <li>Search engines;</li>
              <li>News organizations;</li>
              <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
            </ul>
            <p className="text-white text-justify">
              These organizations may link to our home page to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
            </p>
            <p className="text-white text-justify">
              We may consider and approve other link requests from the following types of organizations:
            </p>
            <ul className="text-white text-justify">
              <li>Commonly-known consumer and/or business information sources;</li>
              <li>Dot.com community sites;</li>
              <li>Associations or other groups representing charities;</li>
              <li>Online directory distributors;</li>
              <li>Internet portals;</li>
              <li>Accounting law and consulting firms; and</li>
              <li>Educational institutions and trade associations.</li>
              <li>System-wide Accredited Businesses</li>
              <li>Soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.</li>
            </ul>
            <p className="text-white text-justify">
              We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of MONT IDA ENTERTAINMENTS LTD; and (d) the link is in the context of general resource information.
            </p>
            <p className="text-white text-justify">
              These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
            </p>
            <p className="text-white text-justify">
              If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website you must inform us by sending an e-mail to MONT IDA ENTERTAINMENTS LTD. Please include your name your organization name contact information as well as the URL of your site a list of any URLs from which you intend to link to our Website and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
            </p>
            <p className="text-white text-justify">
              Approved organizations may hyperlink to our Website as follows:
            </p>
            <ul className="text-white text-justify">
              <li>By use of our corporate name; or</li>
              <li>By use of the uniform resource locator being linked to; or</li>
              <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</li>
            </ul>
            <p className="text-white text-justify">
              No use of MONT IDA ENTERTAINMENTS LTD's logo or other artwork will be allowed for linking a trademark license agreement.
            </p>
            <h4 className="text-primary mb-4 text-justify">iFrames</h4>
            <p className="text-white text-justify">
              Without prior approval and written permission you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.
            </p>
            <h4 className="text-primary mb-4 text-justify">Content Liability</h4>
            <p className="text-white text-justify">
              We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous obscene or criminal or which infringes otherwise violates or advocates the infringement or other violation of any third party rights.
            </p>
            <h4 className="text-primary mb-4 text-justify">Reservation of Rights</h4>
            <p className="text-white text-justify">
              We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website you agree to be bound to and follow these linking terms and conditions.
            </p>
            <h4 className="text-primary mb-4 text-justify">Removal of links from our website</h4>
            <p className="text-white text-justify">
              If you find any link on our Website that is offensive for any reason you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly. We do not ensure that the information on this website is correct we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
            </p>
            <h4 className="text-primary mb-4 text-justify">Disclaimer</h4>
            <p className="text-white text-justify">
              To the maximum extent permitted by applicable law we exclude all representations warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
            </p>
            <ul className="text-white text-justify">
              <li>Limit or exclude our or your liability for death or personal injury;</li>
              <li>Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
              <li>Limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
              <li>Exclude any of our or your liabilities that may not be excluded under applicable law.</li>
            </ul>
            <p className="text-white text-justify">
              The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer including liabilities arising in contract in tort and for breach of statutory duty.
            </p>
            <p className="text-white text-justify">
              As long as the website and the information on the website are provided free of charge we will not be liable for any loss or damage of any nature.
            </p>
            <h4 className="text-primary mb-4 text-justify">Governing Law</h4>
            <p className="text-white text-justify">
              These terms and conditions are governed by and construed in accordance with the laws of Mauritius and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
            </p>
            <h4 className="text-primary mb-4 text-justify">Contact Information</h4>
            <p className="text-white text-justify">
              For any questions or concerns regarding these terms please contact us at contact@mcine.mu or visit our contact page.
            </p>
          </div>
        </div>
      </div>
      </>
    );

};

export default TermsOfUse;
