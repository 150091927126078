import React, { useEffect, useState } from "react";
import {
    Col,
    Card,
    Image,
    Spinner,
} from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import moment from "moment";
import { MdDownloadForOffline } from "react-icons/md";
import { backImageSrc, food_fallback, frontImageSrc, movie_placeholder } from "assets";
import { useMediaQuery } from "react-responsive";
import { getCorrectImageUrl } from "utils";


const FoodOrderCard = ({ foodOrder, show_download_ticket_button }) => {
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const [firstImage, setFirstImage] = useState(frontImageSrc)
    const [secondImage, setSecondImage] = useState(backImageSrc)

    const units = [
        { value: "UNIT", label: "unit" },
        { value: "GRAM", label: "g" },
        { value: "LITER", label: "L" },
        { value: "MILLI_LITER", label: "ml" },
        { value: "POUND", label: "lb" },
        { value: "BASKET", label: "Basket" },
        { value: "PIECE", label: "pc" },
    ]

    const [downloading, setDownloading] = useState(null);
    const [allowDownload, setAllowDownload] = useState(false);

    const downloadFood = async () => {
        const response = await authActions.getFoodPdf(foodOrder.id)
        if (response) {
            setDownloading(false);
        }
    }

    useEffect(() => {
        if (foodOrder?.fooditems?.length >= 2) {
            setFirstImage(foodOrder.fooditems[0].food_data?.image)
            setSecondImage(foodOrder.fooditems[1].food_data?.image)
        } else if (foodOrder?.fooditems?.length === 1) {
            setFirstImage(foodOrder?.fooditems?.[0]?.food_data?.image)
        }

        if (foodOrder) {
            if (foodOrder?.payments[0]?.payment_status === "PAID") {
                setAllowDownload(true);
            } else {
                setAllowDownload(false);
            }
        } else {
            setAllowDownload(false)
        }
    }, [foodOrder]);

    return (
        <div className={`${medium ? "my-4" : "my-1"}`}>
            <Card
                className="py-3 px-4 text-white d-flex flex-column"
                style={{
                    // borderRadius: "10px",
                    borderRadius: "10px 10px 0px 0px",
                    backgroundColor: "rgb(27, 30, 41)"
                    // border: "1px solid #9a9a9a"
                }}
            >
                <div className={`${medium ? "row" : ""} w-100`}>
                    <Col sm={3} className={`p-2 position-relative ${medium ? "" : "w-100"} d-flex justify-content-center`}>
                        <Image className="position-absolute" src={getCorrectImageUrl(secondImage)}
                            style={{
                                maxHeight: 150,
                                maxWidth: 150,
                                width: "90%",
                                borderRadius: 10,
                                objectFit: "cover",
                                backgroundColor: "grey",
                                // top: 20, 
                                // left: 20, 
                                opacity: 0.5,
                                // transform: "translate(50%, 0%)"
                            }}
                            onError={e => e.target.src = food_fallback}
                        />
                        <Image className="position-absolute" src={getCorrectImageUrl(firstImage)}
                            style={{
                                maxHeight: 150,
                                maxWidth: 150,
                                width: "90%",
                                objectFit: "cover",
                                borderRadius: 10,
                                backgroundColor: "#1c1e29",
                                // transform: "translate(50%, 0%)"
                            }}
                            onError={e => e.target.src = food_fallback}
                        />
                    </Col>
                    <Col sm={9} className={`p-0 ${medium ? "" : "w-100"}`} style={{ marginTop: medium ? 0 : 170 }}>
                        <div className="d-flex justify-content-between">
                            <h5>
                                <span>{foodOrder?.order?.theatre_data?.title}</span>
                                <span> | </span>
                                <span>{moment(foodOrder?.order?.show_data?.date).format("DD MMM YYYY")}</span>
                            </h5>
                            {
                                show_download_ticket_button ? <>
                                    {downloading ?
                                        <Spinner size="md" />
                                        :
                                        <div
                                            onClick={() => {
                                                if (allowDownload) {
                                                    setDownloading(true)
                                                    downloadFood()
                                                }
                                            }}
                                        >
                                            <MdDownloadForOffline className={`${allowDownload ? "cursor-pointer" : ""}`} size={30} color={allowDownload ? "#ff0000" : "#ff000050"} />
                                        </div>
                                    } </> : <div></div>}
                        </div>
                        {foodOrder?.fooditems?.map((item, index) => {
                            return (
                                <div className="d-flex justify-content-between" key={index}>
                                    <div>{item.title} ({item.quantity} {units.filter(unit => unit.value === item?.unit)[0].label}) x {item.ordered_quantity}</div>
                                    <div>MUR. {(parseFloat(item.price) * item.ordered_quantity).toFixed(2)}</div>
                                </div>
                            )
                        })}
                        {
                            allowDownload && foodOrder?.barcode ? <div className="d-flex justify-content-center align-items-center my-2">
                                <div className="d-flex justify-content-center align-items-center p-2 pt-4 bg-white" style={{ width: "70%" }}>
                                    <img src={`data:image/png;base64,${foodOrder?.barcode}`} alt="order-barcode" width={"95%"} />
                                </div>
                            </div> : <br />
                        }

                        <div className="d-flex justify-content-between">
                            <div>Subtotal</div>
                            <div>MUR. {foodOrder?.subtotal}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>Discount</div>
                            <div>MUR. {foodOrder?.discount || `0.00`}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>Tax</div>
                            <div>MUR. {foodOrder?.tax || `0.00`}</div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div>TOTAL AMOUNT</div>
                            <h5>MUR. {foodOrder?.payments?.sort((p, q) => p.updated_at > q.updated_at ? -1 : 1)?.[0]?.total}</h5>
                        </div>
                    </Col>
                </div>
            </Card>
            <Card
                className={`${medium ? "" : "mb-5"} mt-1 py-3 px-4 text-white d-flex flex-column`}
                style={{
                    // borderRadius: "10px",
                    borderRadius: "0px 0px 10px 10px",
                    backgroundColor: "rgb(27, 30, 41)"
                    // border: "1px solid #9a9a9a"
                }}
            >
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="text-grey">Order Date & Time</div>
                        <p className="m-0">{moment(foodOrder?.created_at).format("DD MMM YYYY hh:mm a")}</p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="text-grey">Payment Mode</div>
                        <p className="m-0">{foodOrder?.payments[0]?.payment_method}
                            {
                                foodOrder?.payments[0]?.payment_gateway === "MIPS"
                                    ? <span>&nbsp;(MIPS)</span>
                                    : foodOrder?.payments[0]?.payment_gateway === "MYT"
                                        ? <span>&nbsp;(my.t money)</span>
                                        : <></>
                            }
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="text-grey">Payment Status</div>
                        <p className="m-0">{foodOrder?.payments[0]?.payment_status}</p>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default FoodOrderCard;
