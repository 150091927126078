import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image, Accordion, useAccordionButton, Form, Badge, Button, Spinner } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import DateSelection from "website/components/Movie/DateSelection";
import moment from "moment";
import * as authActions from "Redux/Actions/authActions";
import ShowCard from "website/components/Movie/ShowCard";
import { FaCaretDown, FaCaretUp, FaFilter } from "react-icons/fa";
import { Outlet, useNavigate, useParams } from "react-router";
import { movie_placeholder } from "assets";
import NoShows from "website/components/Movie/NoShows/NoShows";
import log from "loglevel";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const CustomToggle = ({ eventKey, selectedDate, setSelectedDate, open, setOpen }) => {
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        setOpen(!open)
    );

    return (
        <div className="bg-slate d-flex justify-content-between align-items-center">
            <DateSelection
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />
            <Button
                className={`${medium ? "py-2" : "py-4"} bg-dark cursor-pointer text-white border-0 ms-1`}
                onClick={decoratedOnClick}
            >
                {medium ?
                    <>
                        {open ? <FaCaretUp size={15} /> : <FaCaretDown size={15} />}
                        <span className="ms-2">Filters</span>
                    </>
                    :
                    <>
                        <FaFilter />
                    </>
                }
            </Button>
        </div>
    );
}

const FilterComponent = ({ item, filters, setFilters }) => {

    const medium = useMediaQuery({ query: "(min-width: 768px)" });

    return (
        <Card
            className={`${item.value ? "text-red" : "text-lightgrey"} bg-slate p-2 m-2 text-center cursor-pointer`}
            style={{ width: "90%", border: item.value ? "2px solid #f5382c" : "1px solid #c6c6c6" }}
            onClick={(e) => {
                setFilters(
                    filters.map((item1) => {
                        if (item1.id === item.id) {
                            log.log(item1.id === item.id)
                            return {
                                id: item1.id,
                                title: item1.title,
                                value: !item1.value,
                            };
                        }
                        return item1;
                    })
                );
            }}
        >
            {item.value ? <b>{item.title}</b> : item.title}
        </Card>
    )

}

const Movies = () => {

    const { movieId } = useParams()
    const navigate = useNavigate()
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const medium = useMediaQuery({ query: "(min-width: 768px)" });
    const [selectedDate, setSelectedDate] = useState(
        moment().format("YYYY-MM-DD")
    );

    const [open, setOpen] = useState(false)
    const [theatres, setTheatres] = useState([])
    const [currentTheatre, setCurrentTheatre] = useState(null)
    const [showData, setShowData] = useState([]);
    const [showDataLoading, setShowDataLoading] = useState(false);
    const [languageFilters, setLanguageFilters] = useState([])
    const [languageString, setLaguageString] = useState(null)
    const [formatTypeFilters, setFormatTypeFilters] = useState([])
    const [formatString, setFormatString] = useState(null)

    const fetchFilterOptions = async () => {
        const response = await authActions.fetchLanguagesFormatTypes();
        if (response) {
            setLanguageFilters(response.languages.map(language => {
                return {
                    "id": language.id,
                    "title": language.title,
                    "value": false,
                }
            }))
            setFormatTypeFilters(response.program_types.map(format_type => {
                return {
                    "id": format_type.id,
                    "title": format_type.title,
                    "value": false,
                }
            }))
        }
    }

    const fetchTheatres = async () => {
        const response = await authActions.fetchClientTheatres(clientId);
        if (response) {
            setTheatres(response.data?.results)
            setCurrentTheatre(response.data?.results[0]?.id)
        }
    }

    const fetchShows = async () => {
        setShowDataLoading(true);
        let lanFilter = []
        let formatFilter = []
        if (languageFilters.length > 0) {
            lanFilter = languageFilters.filter(lan => lan.value).map(lan => { return lan.id })
        }
        if (formatTypeFilters.length > 0) {
            formatFilter = formatTypeFilters.filter(format => format.value).map(format => { return format.id })
        }
        const response = await authActions.fetchSelectedTheatreDateShows(clientId, currentTheatre, selectedDate, lanFilter, formatFilter);
        if (response) {
            setShowData(response.data)
        }
        setShowDataLoading(false);
    }

    useEffect(() => {
        fetchFilterOptions()
        if (clientId) {
            fetchTheatres()
        }
    }, [clientId])

    useEffect(() => {
        if (clientId && currentTheatre) {
            fetchShows()
        }
    }, [clientId, currentTheatre, selectedDate, languageFilters, formatTypeFilters])

    useEffect(() => {
        if (languageFilters.filter(lan => lan.value).length > 0) {
            setLaguageString(languageFilters.filter(lan => lan.value).map(lan => { return lan.title }).join(", "))
        } else {
            setLaguageString(null)
        }
        if (formatTypeFilters.filter(format => format.value).length > 0) {
            setFormatString(formatTypeFilters.filter(format => format.value).map(format => { return format.title }).join(", "))
        } else {
            setFormatString(null)
        }
    }, [languageFilters, formatTypeFilters])

    if (movieId) {
        return <Outlet />
    }

    return (
        <>
            <Helmet>
                <title>MCine | Movies</title>
            </Helmet>
            <div>
                <div className="bg-slate pb-5" style={{ minHeight: "90vh" }}>
                    <div className="wrapper">
                        <ul className="breadcrumb subtitle mx-1 pb-4">
                            <li><a href="/">Home</a></li>
                            <li>Movies</li>
                        </ul>
                        <Form.Select
                            required
                            name="theatre"
                            type="text"
                            className={`${medium ? "w-25" : "w-100"} text-lightgrey cursor-pointer theatre-select p-3 mb-4 border-0`}
                            value={currentTheatre}
                            onChange={(e) => setCurrentTheatre(e.target.value)}
                        >
                            {theatres?.length > 0 && theatres
                                ?.sort((a, b) => a.title.localeCompare(b.title))
                                .map((option) => {
                                    return (
                                        <option
                                            className="subtitle"
                                            key={option.id}
                                            value={option.id}
                                        >
                                            {option.title}
                                        </option>
                                    );
                                })}
                        </Form.Select>
                        <Accordion className="user-select-none" defaultActiveKey="">
                            <Card className="bg-slate border-0">
                                <Card.Header className="p-0 border-0">
                                    <CustomToggle eventKey="0" selectedDate={selectedDate} setSelectedDate={setSelectedDate} open={open} setOpen={setOpen} />
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body className="text-white p-0 mt-4">
                                        Select Language
                                        <Row className={`${medium ? "row-cols-4" : "row-cols-3"} py-3 d-flex justify-content-start align-items-start`}>
                                            {languageFilters?.map?.((lan, ind) => {
                                                return (
                                                    <div key={ind}>
                                                        <FilterComponent
                                                            item={lan}
                                                            key={ind}
                                                            filters={languageFilters}
                                                            setFilters={setLanguageFilters}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </Row>
                                        Format Type
                                        <Row className={`${medium ? "row-cols-4" : "row-cols-3"} py-3 d-flex justify-content-start align-items-start`}>
                                            {formatTypeFilters.map((format, indx) => {
                                                return (
                                                    <div key={indx}>
                                                        <FilterComponent
                                                            item={format}
                                                            filters={formatTypeFilters}
                                                            setFilters={setFormatTypeFilters}
                                                        />
                                                    </div>
                                                )
                                            })}
                                        </Row>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        {(languageString || formatString) &&
                            <div className="mt-4 text-white">
                                <span>Searches for</span>
                                {languageString && <span> <b>{languageString}</b> movies</span>}
                                {languageString && formatString &&
                                    <span> in</span>
                                }
                                {formatString && <span> {!languageString && "movies in "}<b>{formatString}</b></span>}
                            </div>
                        }
                        {
                            showDataLoading ?
                                <div className="text-white d-flex justify-content-center align-items-center my-5 py-5">
                                    <Spinner size="sm" />
                                </div> : <>
                                    {showData.length > 0 ?
                                        showData.map((data, indx) => {
                                            return (
                                                <Row key={indx} className="mt-4">
                                                    <Col sm={2}>
                                                        <Link to={`/movies/${data.movie?.id}`} className="d-flex flex-column d-block">
                                                            <img
                                                                src={data.movie?.poster_image}
                                                                alt={`${data.movie?.title} poster`}
                                                                className="rounded cursor-pointer"
                                                                style={{ width: 150 }}
                                                                onError={(e) => {
                                                                    e.target.src = movie_placeholder
                                                                }}
                                                            />
                                                        </Link>
                                                    </Col>
                                                    <Col sm={10}>
                                                        <div>
                                                            <h5 className="text-white m-0">{data.movie?.title}</h5>
                                                            <div className="text-lightgrey my-4">
                                                                {data?.movie?.overview}
                                                            </div>
                                                            <div className="d-flex flex-column mb-2">
                                                                {data?.movie?.release_date && (
                                                                    <div className="text-feather m-0">
                                                                        Release Date : &nbsp;
                                                                        <span className="text-lightgrey">{moment(data.movie?.release_date).format("DD MMM, YYYY")}</span>
                                                                    </div>
                                                                )}
                                                                {data?.movie?.runtime && (
                                                                    <div className="text-feather">
                                                                        Runtime : &nbsp;
                                                                        <span className="text-lightgrey">{data.movie?.runtime} m</span>
                                                                    </div>
                                                                )}
                                                                <div className="text-feather">
                                                                    Cast : &nbsp;
                                                                    <span className="text-lightgrey">
                                                                        {data.movie?.program_data?.cast?.slice(0, 5).map((c, i) => {
                                                                            return c.name + (i === 4 ? "" : ", ")
                                                                        })}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <hr className="text-white" />
                                                            <h5 className="text-white mb-3">Shows - {moment(selectedDate).format("DD MMM, YYYY")}</h5>
                                                            <Row className="m-0">
                                                                <ShowCard
                                                                    shows={data.shows}
                                                                    movieId={data.movie?.id}
                                                                />
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                        :
                                        <div
                                            className="p-0 text-white d-flex justify-content-center align-items-center"
                                            style={{ minHeight: "20vh" }}
                                        >
                                            {/* No shows for the day! */}
                                            <NoShows />
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const styles = {
    parentContainer: {
        padding: "40px 100px",
    },
    mobileParentContainer: {
        padding: "20px 10px",
    },
}

export default Movies;