import React, { useEffect, useState } from "react";
import { Badge, Card } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { useSpring, animated } from "react-spring";
import { FaRegCirclePlay } from "react-icons/fa6";
import HorizontalScrollableText from "../Common/HorizontalScrollableText";
import { movie_placeholder } from "assets";
import { Link } from "react-router-dom";


const MovieGridCard = ({ id, title, image, genres, rating, showRating, certification, showCertification, trailerLink, setOpenModal, setCurrentFilm }) => {
  const navigate = useNavigate();
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const xlarge = useMediaQuery({ query: "(min-width: 1200px)" });

  const [hover, setHover] = useState(false)
  const [playButton, setPlayButton] = useState(false)
  const [genresString, setGenresString] = useState("");
  const [moviePoster, setMoviePoster] = useState(image);

  const props_desktop = useSpring({
    opacity: 1,
    transform: hover ? "scale(1.2)" : "scale(1)",
    backgroundColor: "rgba(0, 0, 0, 0.5)"
  });

  const props_mobile = useSpring({
    opacity: 1,
    backgroundColor: 'black'
  });

  useEffect(() => {
    let genres_string = "";
    let end = null
    if (genres) {
      for (let i = 0; i < genres?.slice(0, 4).length; i++) {
        end = genres?.slice(0, 4).length === i + 1 ? "" : ", "
        genres_string = genres_string + genres[i].name + end
      }
    }
    setGenresString(genres_string)
  }, [genres])

  return (
    <div className="position-relative">
      <Card
        className={`bg-dark w-100 mb-3 flex-grow-1 cursor-pointer overflow-hidden`}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <animated.div
          key={id}
          style={large ? props_desktop : props_mobile}
        >
          <Card.Img
            variant="top"
            src={moviePoster}
            className="img-fluid w-100 user-select-none"
            style={{
              objectFit: "cover",
              // height: xlarge ? "60vh" : large ? "50vh" : medium ? "40vh" : small ? "40vh" : "30vh",
              height: "auto",
            }}
            onError={() => setMoviePoster(movie_placeholder)}
          />
          {/* ---- for overlay on image ---- */}
          {hover && (
            <div
              className="position-absolute w-100 h-100 top-0 start-0 d-flex align-items-center justify-content-center"
              style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            >
            </div>
          )}

        </animated.div>
        {large && hover &&
          <div
            className="position-absolute"
            style={{ top: "45%", right: "45%", zIndex: 99, backdropFilter: "blur(20px)", borderRadius: "20px" }}
            onMouseEnter={() => setPlayButton(true)}
            onMouseLeave={() => setPlayButton(false)}
            onClick={() => {
              setOpenModal(true)
              setCurrentFilm(trailerLink)
            }}
          >
            <FaRegCirclePlay size={50} style={{ color: playButton ? "#fece50" : "white" }} />
          </div>
        }
        {!large &&
          <div
            className="position-absolute cursor-pointer"
            style={{ top: "10px", right: "10px", zIndex: 99, backdropFilter: "blur(20px)", borderRadius: "20px" }}
            onClick={() => {
              setOpenModal(true)
              setCurrentFilm(trailerLink)
            }}
          >
            <FaRegCirclePlay size={30} style={{ color: "#fff" }} />
          </div>
        }
      </Card>
      <Link to={`/movies/${id}`} className="mb-4 cursor-pointer d-block" style={{textDecoration: "none", color: "inherit"}} >
        {/* <h4 style={{ color: "#fece50" }}>{title} ({rating}/10) {certification}</h4> */}
        {/* <h4 className="scroll-text" style={{ color: "white" }}>
          {title}
        </h4> */}
        <HorizontalScrollableText>
          <h4 style={{ color: "white" }}>
            {title}
          </h4>
        </HorizontalScrollableText>
        <div className="d-flex">
          <span style={{ color: "white" }}>
            {/* {showRating && <span className="px-2">•</span>} */}
            {showRating ? <span style={{ color: "#fece50" }}>{`${Number.isInteger(rating) ? rating : rating.toFixed(1)}/10`}</span> : ""}
            {showRating && showCertification && certification && <span className="px-2">•</span>}
            {showCertification && certification ? `${certification}` : ""}
          </span>
          {genres && (
            <>
              <span className="px-2 text-white">•</span>
              {/* <div className="text-feather scroll-text" title={genresString}> */}
              {/* {genres?.slice(0, 3).map((item, i) => (
              <p key={item.id} className="text-feather me-2">{`${item.name}${genres.length === i + 1 ? "" : ", "
              }`}</p>
              ))} */}
              {/* {genresString} */}
              {/* </div> */}
              <HorizontalScrollableText hoverText={genresString}>
                <div className="text-feather">{genresString}</div>
              </HorizontalScrollableText>
            </>
          )}
        </div>

        {/* {["Hindi", "English"].map((lang, i) => (
          <Badge key={i} bg="extradark me-2 mb-2">
            {lang}
          </Badge>
        ))} */}
      </Link>
    </div>
  );
};

export default MovieGridCard;
