import React, { useEffect, useRef, useState } from "react";
import MovieDetailsShowcaseVertical from "website/components/Movie/MovieDetailsShowcaseVertical";
import DateSelection from "website/components/Movie/DateSelection";
import moment from "moment";
import { Badge, Col, Container, Row, Spinner } from "react-bootstrap";
import ShowCard from "website/components/Movie/ShowCard";
import { useMediaQuery } from "react-responsive";
import { Navigate, Outlet, useLocation, useNavigate, useParams } from "react-router";
import * as authActions from "Redux/Actions/authActions";
import NoShows from "website/components/Movie/NoShows/NoShows";
import log from "loglevel";
import { Helmet } from "react-helmet";

const SelectShow = () => {

  const navigate = useNavigate();

  const { movieId } = useParams();
  const clientId = process.env.REACT_APP_CLIENT_ID;

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [movieData, setMovieData] = useState([])
  const [showData, setShowData] = useState([])
  const [showDataLoading, setShowDataLoading] = useState(false);

  const large = useMediaQuery({ query: "(min-width: 992px)" });

  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const showParam = params.length > 0 ? params[0] : "";
  const movieIdParam = params.length > 1 ? params[1] : "";
  const seatsParam = params.length > 2 ? params[2] : "";
  const showIdParam = params.length > 2 ? params[3] : "";

  // console.log({showParam, movieIdParam, seatsParam, showIdParam})

  const fetchMovie = async () => {
    const response = await authActions.fetchClientProgram(movieId, navigate);
    if (response.status === 200) {
      setMovieData(response.data)
    }
  };

  const fetchShows = async () => {
    setShowDataLoading(true);
    const response = await authActions.fetchSelectedDateShows(clientId, movieId, selectedDate);
    if (response.status === 200) {
      setShowData(response.data)
    }
    setShowDataLoading(false);
  };

  useEffect(() => {
    if (movieId) {
      fetchMovie()
    }
  }, [movieId])

  useEffect(() => {
    fetchShows()
  }, [selectedDate])

  log.log({showParam, movieIdParam, seatsParam, showIdParam})
  if (
    (showParam && !movieIdParam)
    ||
    (showParam && movieIdParam && seatsParam && (seatsParam !== "seats"))
    ||
    (showParam && movieIdParam && seatsParam && (seatsParam === "seats") && !showIdParam)) {
    return <Navigate to={'/movies'} />
  }

  if (seatsParam) {
    return <Outlet />
  }

  return (
    <>
      <Helmet>
        <title>{`MCine | Movies > ${movieData.title} > Shows`}</title>
      </Helmet>
      <div className="bg-slate">
        <div className="wrapper px-0">
          <ul className="breadcrumb subtitle mx-1 pb-4">
            <li><a href="/">Home</a></li>
            <li><a href="/movies">Movies</a></li>
            <li><a href={`/movies/${movieId}`}>{movieData.title}</a></li>
            <li>Shows</li>
          </ul>
        </div>
        <div className="wrapper" style={{ minHeight: "90vh" }}>
          {!large &&
            <>
              <div className="d-flex justify-content-start">
                <img
                  src={movieData?.poster_image}
                  alt={`${movieData?.title} poster`}
                  className="rounded"
                  style={{ width: 100 }}
                />
                <div className="ms-3">
                  <h4 className="text-white mb-3">{movieData?.title}</h4>
                  <div className="mb-2">
                    {["Hindi", "English"].map((lang, i) => (
                      <Badge key={i} bg="white text-black me-2 mb-2 p-2">
                        {lang}
                      </Badge>
                    ))}
                  </div>
                  <div className="d-flex">
                    {movieData?.release_date && (
                      <p className="text-feather me-2">{moment(movieData?.release_date).format("DD MMM, YYYY")}</p>
                    )}
                    {movieData?.runtime && (
                      <p className="text-feather">• {movieData?.runtime} m</p>
                    )}
                    {movieData?.show_certification && (
                      <p className="text-feather ms-2">• {movieData?.certification_data?.title}</p>
                    )}
                  </div>
                </div>
              </div>
            </>
          }
          <Row>
            <Col lg={8}>
              <div className="py-4 pb-5">
                <div className="mb-4">
                  <DateSelection
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                  />
                </div>
                <div className="mb-1">
                  <h4 className="text-white mb-3">Shows</h4>
                  <Row className="m-0">
                    {
                      showDataLoading ?
                        <div
                          className="d-flex justify-content-center align-items-center text-white w-100"
                          style={{ minHeight: "70vh" }}
                        >
                          <Spinner size="sm" />
                        </div> : <>
                          {showData.length > 0 ?
                            showData.map((item, index) => {
                              return (
                                <ShowCard
                                  key={item?.id || index}
                                  theatre={item?.theatre}
                                  shows={item?.shows}
                                  movieId={movieId}
                                />
                              );
                            })
                            :
                            <div
                              className="p-0 text-white d-flex justify-content-center align-items-center"
                              style={{ minHeight: "20vh" }}
                            >
                              {/* No shows for the day! */}
                              <NoShows />
                            </div>
                          }
                        </>
                    }
                  </Row>
                </div>
              </div>
            </Col>
            {large &&
              <Col sm={4}>
                <MovieDetailsShowcaseVertical
                  title={movieData?.title}
                  image={movieData?.poster_image}
                  genres={movieData?.program_data?.genres}
                  runtime={movieData?.runtime}
                  releaseDate={movieData?.release_date}
                  certification={movieData?.certification_data?.title}
                  showCertification={movieData?.show_certification}
                />
              </Col>}
          </Row>
        </div>
      </div>
    </>
  );
};
const styles = {
  parentContainer: {
    padding: "40px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
};

export default SelectShow;
