import React from "react";
import PageTitle from "website/components/Common/PageTitle";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <><Helmet>
      <title>MCine | Privacy Policy</title>
    </Helmet>
    <div>
      <div className="bg-slate pb-5" style={{ minHeight: "35vh" }}>
        <div className="wrapper">
          <ul className="breadcrumb subtitle mx-1 pb-4">
            <li>
              <a href="/">Home</a>
            </li>
            <li>Privacy policy</li>
          </ul>
          <h4 className="text-primary mb-4 text-justify">
            Your privacy is critically important to us.
          </h4>
          <p className="text-white text-justify" style={{ whiteSpace: "pre-line" }}>
            MONT IDA ENTERTAINMENTS LTD is located at:
            <br />
            MONT IDA ENTERTAINMENTS LTD TRIANON
            <br />
            SHOPPING PARK TRIANON, QUATRE BORNES,
            <br />
            Mauritius 4641900
          </p>
          <p className="text-white text-justify">
          It is MONT IDA ENTERTAINMENTS LTD's policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to https://www.mcine.mu (hereinafter, "us", "we", or "https://www.mcine.mu"). We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this privacy policy ("Privacy Policy") to explain what information may be collected on our Website, how we use this information, and under what circumstances we may disclose the information to third parties. This Privacy Policy applies only to information we collect through the Website and does not apply to our collection of information from other sources.
            <br />
            <br />
            This Privacy Policy, together with the Terms and Conditions posted on our Website, sets forth the general rules and policies governing your use of our Website. Depending on your activities when visiting our Website, you may be required to agree to additional terms and conditions.
          </p>
          <h4 className="text-primary mb-4 text-justify">Website Visitors</h4>
          <p className="text-white text-justify">
          Like most website operators, MONT IDA ENTERTAINMENTS LTD collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. MONT IDA ENTERTAINMENTS LTD's purpose in collecting non-personally identifying information is to better understand how MONT IDA ENTERTAINMENTS LTD's visitors use its website. From time to time, MONT IDA ENTERTAINMENTS LTD may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. MONT IDA ENTERTAINMENTS LTD also collects potentially personally-identifying information like Internet Protocol (IP) addresses, names, phone numbers, and email addresses for users interacting with our website.
          </p>
          <h4 className="text-primary mb-4 text-justify">
            Gathering of Personally-Identifying Information
          </h4>
          <p className="text-white text-justify">
          Certain visitors to MONT IDA ENTERTAINMENTS LTD's websites choose to interact with MONT IDA ENTERTAINMENTS LTD in ways that require MONT IDA ENTERTAINMENTS LTD to gather personally-identifying information. The amount and type of information that MONT IDA ENTERTAINMENTS LTD gathers depend on the nature of the interaction. For example, we ask visitors who sign up for an account or make a booking to provide a name, phone number, and email address.
          </p>
          <h4 className="text-primary mb-4 text-justify">Security</h4>
          <p className="text-white text-justify">
          The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
          </p>
          <h4 className="text-primary mb-4 text-justify">Advertisements</h4>
          <p className="text-white text-justify">
          Ads appearing on our website may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognize your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by MONT IDA ENTERTAINMENTS LTD and does not cover the use of cookies by any advertisers.
          </p>

          <h4 className="text-primary mb-4 text-justify">Links To External Sites</h4>
          <p className="text-white text-justify">
          Our Service may contain links to external sites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy and terms and conditions of every site you visit. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party sites, products, or services.
          </p>
          <h4 className="text-primary mb-4 text-justify">
            Protection of Certain Personally-Identifying Information
          </h4>
          <p className="text-white text-justify">
          MONT IDA ENTERTAINMENTS LTD discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors, and affiliated organizations that (i) need to know that information in order to process it on MONT IDA ENTERTAINMENTS LTD's behalf or to provide services available at MONT IDA ENTERTAINMENTS LTD's website, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors, and affiliated organizations may be located outside of your home country; by using MONT IDA ENTERTAINMENTS LTD's website, you consent to the transfer of such information to them. MONT IDA ENTERTAINMENTS LTD will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors, and affiliated organizations, as described above, MONT IDA ENTERTAINMENTS LTD discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order, or other governmental request, or when MONT IDA ENTERTAINMENTS LTD believes in good faith that disclosure is reasonably necessary to protect the property or rights of MONT IDA ENTERTAINMENTS LTD, third parties, or the public at large.<br></br><br></br>
          If you are a registered user of https://www.mcine.mu and have supplied your email address, MONT IDA ENTERTAINMENTS LTD may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with MONT IDA ENTERTAINMENTS LTD and our products. We primarily use our social media pages to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. MONT IDA ENTERTAINMENTS LTD takes all measures reasonably necessary to protect against the unauthorized access, use, alteration, or destruction of potentially personally-identifying and personally-identifying information.
          </p>
          <h4 className="text-primary mb-4 text-justify">Aggregated Statistics</h4>
          <p className="text-white text-justify">
          MONT IDA ENTERTAINMENTS LTD may collect statistics about the behaviour of visitors to its website. MONT IDA ENTERTAINMENTS LTD may display this information publicly or provide it to others. However, MONT IDA ENTERTAINMENTS LTD does not disclose your personally-identifying information.
          </p>
          <h4 className="text-primary mb-4 text-justify">Cookies</h4>
          <p className="text-white text-justify">
          To enrich and perfect your online experience, MONT IDA ENTERTAINMENTS LTD uses "Cookies", similar technologies and services provided by others to display personalized content, appropriate advertising, and store your preferences on your computer. A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. MONT IDA ENTERTAINMENTS LTD uses cookies to help MONT IDA ENTERTAINMENTS LTD identify and track visitors, their usage of https://www.mcine.mu, and their website access preferences. MONT IDA ENTERTAINMENTS LTD visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using MONT IDA ENTERTAINMENTS LTD's websites, with the drawback that certain features of MONT IDA ENTERTAINMENTS LTD's websites may not function properly without the aid of cookies. By continuing to navigate our website without changing your cookie settings, you hereby acknowledge and agree to MONT IDA ENTERTAINMENTS LTD's use of cookies.
          </p>
          <h4 className="text-primary mb-4 text-justify">E-commerce</h4>
          <p className="text-white text-justify">
          Those who engage in transactions with MONT IDA ENTERTAINMENTS LTD - by purchasing MONT IDA ENTERTAINMENTS LTD's services or products, are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, MONT IDA ENTERTAINMENTS LTD collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with MONT IDA ENTERTAINMENTS LTD. MONT IDA ENTERTAINMENTS LTD does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.
          </p>
          <h4 className="text-primary mb-4 text-justify">Privacy Policy Changes</h4>
          <p className="text-white text-justify">
          Although most changes are likely to be minor, MONT IDA ENTERTAINMENTS LTD may change its Privacy Policy from time to time, and in MONT IDA ENTERTAINMENTS LTD's sole discretion. MONT IDA ENTERTAINMENTS LTD encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.
          </p>
          <h4 className="text-primary mb-4 text-justify">Credit & Contact Information</h4>
          <p className="text-white text-justify">
          If you have any questions about this Privacy Policy, please contact us via email at contact@mcine.mu or phone at +230 464 1900.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default PrivacyPolicy;
