import React, { useEffect, useState, useRef } from "react";
import { Button, Container, Nav, Navbar, NavDropdown, Modal, Spinner, Form, Row, Col } from "react-bootstrap";
import { CgMenu } from "react-icons/cg";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mcine } from "assets";
import LoginModal from "components/Auth/LoginModal";
import { useDispatch, useSelector } from "react-redux";
import { notify } from "Utilities/Notifications/Notify";
import MovingNavbar from "./MovingNavbar";
import PageTitle from "../Common/PageTitle";
import * as authSlices from "Redux/AuthReducer/authSlices";
import * as authActions from "Redux/Actions/authActions";
import { BiLogOut } from "react-icons/bi";
import CountryCodeFormField from "components/Auth/CountryCodeFormField";
import log from "loglevel";

const useOutsideClickAndScroll = (ref, callback) => {
  useEffect(() => {
    const handleClickOrScroll = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOrScroll);
    window.addEventListener("scroll", handleClickOrScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOrScroll);
      window.removeEventListener("scroll", handleClickOrScroll);
    };
  }, [ref, callback]);
};

const NavLink = ({ title, route }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const [hover, setHover] = useState(null);

  return (
    <Nav.Link
      className={`text-center py-2 mx-xl-2 ${param1 === route ? "border-primary" : ""
        }`}
      onClick={() => {
        navigate(`/${route}`);
      }}
      onMouseEnter={() => setHover(route)}
      onMouseLeave={() => setHover(null)}
      style={{
        color:
          hover || hover == ""
            ? hover === route
              ? "red"
              : "white"
            : param1 === route
              ? "red"
              : "white",
      }}
    >
      {title}
    </Nav.Link>
  );
};

const navbarItems = [
  { id: 1, title: "Home", route: "" },
  { id: 2, title: "Movies", route: "movies" },
  { id: 3, title: "About", route: "about" },
  { id: 4, title: "Our Cinemas", route: "our-cinemas" },
  { id: 5, title: "Contact", route: "contact" },
];

const additionalRouteItems = [
  { id: 1, title: "Privacy Policy", route: "privacy-policy" },
  { id: 2, title: "Terms of Use", route: "terms-of-use" },
  { id: 3, title: "Security", route: "security-policy" },
  { id: 4, title: "Booking Policy", route: "booking-policy" },
];

const NavBar = () => {
  log.log("navbar rerendered")
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const form = useRef(null);
  const timerRef = useRef(null);
  const otpform = useRef(null);
  const small = useMediaQuery({ query: "(min-width: 576px)" });
  const medium = useMediaQuery({ query: "(min-width: 768px)" });
  const large = useMediaQuery({ query: "(min-width: 992px)" });
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = location.pathname.split("/").filter((item) => item !== "");
  const param1 = params.length > 0 ? params[0] : "";
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showForm, setShowForm] = useState("login");
  const [isScrolled, setIsScrolled] = useState(false);
  const [accHover, setAccHover] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [showCompulsaryModal, setShowCompulsaryModal] = useState(false);
  const [updateVerifyToggle, setUpdateVerifyToggle] = useState(true);
  const [isUpdatingInfo, setIsUpdatingInfo] = useState(false)
  const [timer, setTimer] = useState(0);
  const [resending, setResending] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);

  const navbarRef = useRef(null);
  useOutsideClickAndScroll(navbarRef, () => setIsNavbarCollapsed(old => old ? false : old));

  const toggleAccountDropdown = () => {
    setAccHover(!accHover);
    setShowAccountDropdown(!showAccountDropdown);
  };

  // const specialPaddingPages = ["about", "our-cinemas", "contact"];
  // const currentPage = window.location.filter((item) => item !== "")[0];
  // const isSpecialPage = specialPaddingPages.includes

  const handleLogout = async () => {
    dispatch(authSlices.userLogout());
    // const response = await authSlices.userLogout();
    // if (response) {
    navigate("/");
    // }
  };

  const handleUserInfoSubmit = async (e) => {
    e.preventDefault();

    setIsUpdatingInfo(true);
    const formData = new FormData(form.current);
    formData.append("id", user.id);
    formData.append("username", user.username);

    const response = await authSlices.userUpdateWithMobileCheck(formData);
    log.log({ response });
    if (response) {
      dispatch(authSlices.saveUserInfo(response.user));
      // setTimer(response.time_left);
      // e.target.reset();
      form.current.reset();
      setTimeout(() => setUpdateVerifyToggle(() => false), 1000);
      notify("Profile has been updated!", "success");
    }
    setIsUpdatingInfo(false);
  };

  const resendOtpRequest = async (e) => {
    e.preventDefault();
    setResending(true);
    const response = await authSlices.resendOtp({
      id: user.id,
      username: user.username,
      mobile: user.mobile,
    });
    if (response) {
      log.log(response);
      setTimer(response.time_left);
    }
    setResending(false);
  }

  const handleVerifyMobile = async (e) => {
    e.preventDefault();
    setIsUpdatingInfo(true);
    const formData = new FormData(otpform.current);
    formData.append("username", user?.username);
    const response = await authActions.verifyMobile(formData);
    log.log({ response });
    if (response) {
      dispatch(authSlices.updateMobileActive());
      notify(response.msg, "success")
      otpform.current.reset();
      setShowCompulsaryModal(false);
    }
    setIsUpdatingInfo(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user.email === null || user.mobile === null || user.first_name === null || user.last_name === null || !user.is_mobile_active) {
        setShowCompulsaryModal(true);
        setUpdateVerifyToggle(true);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (timer > 0) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY > 100;
      setIsScrolled(scrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {param1 !== "" && (
        <Container as={'header'} fluid className="breadcrumb-background">
          <Container
            style={
              large
                ? [
                  "about",
                  "movies",
                  "contact",
                  "our-cinemas",
                  "privacy-policy",
                  "terms-of-use",
                  "security-policy",
                  "booking-policy",
                ].includes(param1)
                  ? styles.parentContainer
                  : styles.parentContainerLessPadding
                : styles.mobileParentContainer
            }
          >
            <MovingNavbar showForm={showForm} setShowForm={setShowForm} />
            {navbarItems.filter((item, index) => item.route === param1).length >
              0 ? (
              <PageTitle
                title={
                  navbarItems.filter((item, index) => item.route === param1)[0]
                    .title
                }
              />
            ) : (
              additionalRouteItems.filter(
                (item, index) => item.route === param1
              ).length > 0 && (
                <PageTitle
                  title={
                    additionalRouteItems.filter(
                      (item, index) => item.route === param1
                    )[0].title
                  }
                />
              )
            )}
          </Container>
        </Container>
      )}
      {isScrolled && (
        <Navbar
          expand="lg"
          fixed="top"
          className="bg-slate border-bottom border-dark"
          ref={navbarRef}
          onToggle={setIsNavbarCollapsed}
          expanded={isNavbarCollapsed}
        >
          {/* const [navHover, setNavHover] = useState(null); */}
          <Container className="wrapper px-3 py-2">
            <Navbar.Brand href="/" className="d-flex align-items-center">
              <img
                src={mcine}
                alt=""
                className="img-fluid me-2"
                style={{ height: 40 }}
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="navbarScroll"
              className="focus-none p-1 border-0"
            >
              <CgMenu size={medium ? 28 : small ? 24 : 22} color="#fff" />
            </Navbar.Toggle>

            <Navbar.Collapse
              id="navbarScroll"
              className={
                large ? "d-flex justify-content-end" : "bg-extradark pb-4"
              }
              style={{ borderRadius: 10 }}
            >
              <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                <Nav
                  className={`${large
                    ? "ms-auto my-2 my-lg-0 font-inter"
                    : "my-2 my-lg-0 font-inter"
                    }`}
                  navbarScroll
                  style={{ fontWeight: 600, fontSize: "14px" }}
                >
                  {navbarItems.map((item, i) => (
                    <div key={i} className="ms-3">
                      <NavLink title={item?.title} route={item?.route} />
                    </div>
                  ))}
                  {!isAuthenticated ? (
                    param1 !== "login" && (
                      <Button
                        className="bg-primary primary-button border border-primary ms-3 px-4"
                        onClick={() => {
                          // navigate("/login");
                          setShowLoginModal(!showLoginModal);
                        }}
                        style={{ width: 100, borderRadius: 20 }}
                      >
                        Login
                      </Button>
                    )
                  ) : (
                    <NavDropdown
                      title="Account"
                      className="ms-3 text-center text-lg-start mx-xl-2 text-white custom-dropdown-style"
                    // show={showAccountDropdown}
                    // onMouseEnter={toggleAccountDropdown}
                    // onMouseLeave={toggleAccountDropdown}
                    >
                      <NavDropdown.Item
                        as={Link}
                        to={"/account/profile"}
                        className={`py-2 ps-3 text-center text-lg-start px-xl-2 ${accHover === "profile" ? "text-primary" : "text-white"
                          }`}
                        onMouseEnter={() => setAccHover("profile")}
                        onMouseLeave={() => setAccHover(null)}
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Profile
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={handleLogout}
                        className={`py-2 px-xl-2 text-center text-lg-start ${accHover === "logout" ? "text-primary" : "text-white"
                          }`}
                        onMouseEnter={() => setAccHover("logout")}
                        onMouseLeave={() => setAccHover(null)}
                        style={{ fontWeight: 600, fontSize: "14px" }}
                      >
                        Logout
                      </NavDropdown.Item>
                    </NavDropdown>
                  )}
                </Nav>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      <LoginModal
        showLoginModal={showLoginModal}
        setShowLoginModal={setShowLoginModal}
        showForm={showForm}
        setShowForm={setShowForm}
      />
      {(user?.email === null ||
        user?.mobile === null ||
        user?.first_name === null ||
        user?.last_name === null ||
        !user?.is_mobile_active
      ) && isAuthenticated && (
          <div>
            <Modal
              show={showCompulsaryModal}
              style={{ zIndex: 3000 }}
              dialogClassName="d-flex justify-content-center"
              contentClassName="bg-dark"
              centered
              // backdrop={isAuthenticated ? "static" : true}
              animation={false}
            >
              <Modal.Header closeButton={false} className="d-flex border-grey d-flex justify-content-center">
                <Modal.Title className="text-white">
                  {(user?.email && user?.mobile && user?.first_name && user?.last_name && !user?.is_mobile_active) ? "Verify Mobile" : "Update User Info"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="px-5">
                <div className="bg-dark border-grey rounded">
                  {updateVerifyToggle ?
                    <Form
                      className="text-start w-100"
                      ref={form}
                      onSubmit={(e) => handleUserInfoSubmit(e)}
                    >
                      {/* <Form.Group className="mb-3">
                        <Form.Label className="text-white">Mobile<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile"
                          placeholder="9999999999"
                          className="bg-extradark py-2 border-grey text-white"
                          defaultValue={user?.mobile}
                          required
                        />
                      </Form.Group> */}
                      <CountryCodeFormField initialMobileCodeValue={user?.phone_code} initialMobileValue={user?.mobile} />
                      <Form.Group className="mb-3">
                        <Form.Label className="text-white">First Name<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="first_name"
                          placeholder="John"
                          className="bg-extradark py-2 border-grey text-white"
                          defaultValue={user?.first_name}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="text-white">Last Name<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control
                          type="text"
                          name="last_name"
                          placeholder="Doe"
                          className="bg-extradark py-2 border-grey text-white"
                          defaultValue={user?.last_name}
                          required
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Button
                            className="bg-transparent hover-primary border-primary w-100 my-2 py-2"
                            type="button"
                            onClick={handleLogout}
                          >
                            <BiLogOut />
                            &nbsp;
                            Logout
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            className="bg-primary border-primary w-100 my-2 py-2"
                            type="submit"
                            disabled={isUpdatingInfo}
                          >
                            {isUpdatingInfo ? (
                              <span>
                                <Spinner size="sm" className="me-2" />
                                UPDATING ....
                              </span>
                            ) : (
                              <span className="poppins">UPDATE</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    :
                    <Form
                      className="text-start w-100"
                      ref={otpform}
                      onSubmit={(e) => handleVerifyMobile(e)}
                    >
                      <Form.Group className="mb-3">
                        <Form.Label className="text-white">OTP<span style={{ color: 'red' }}> *</span></Form.Label>
                        <Form.Control
                          type="password"
                          name="mobile_verification_otp"
                          placeholder="1234"
                          className="bg-extradark py-2 border-grey text-white"
                          required
                        />
                      </Form.Group>
                      <div className="d-flex justify-content-center align-items-center">
                        <div
                          className={`${timer !== 0 ? "text-lightgrey" : "text-white"} ${!resending ? "cursor-pointer" : "cursor-not-allowed"}`}
                          onClick={(e) => {
                            if (timer === 0) {
                              resendOtpRequest(e);
                            }
                          }}
                        >
                          {resending ? "Resending..." : "Resend OTP"}
                        </div>
                        <div
                          className={`${timer !== 0 ? "text-white mx-2 caption" : "d-none"
                            }`}
                        >
                          {`in ${timer} seconds`}
                        </div>
                      </div>
                      <Button
                        className="bg-primary border-primary w-100 my-2 py-2"
                        type="submit"
                        disabled={isUpdatingInfo}
                      >
                        {isUpdatingInfo ? (
                          <span>
                            <Spinner size="sm" className="me-2" />
                            VERIFYING ....
                          </span>
                        ) : (
                          <span className="poppins">VERIFY</span>
                        )}
                      </Button>
                      <Button
                        className="bg-transparent hover-primary border-primary w-100 my-2 py-2"
                        type="button"
                        onClick={handleLogout}
                      >
                        <BiLogOut />
                        &nbsp;
                        Logout
                      </Button>
                    </Form>
                  }
                </div>
              </Modal.Body>
            </Modal>
          </div>
          // </div>
        )}
    </>
  );
};

const styles = {
  parentContainer: {
    padding: "40px 100px",
  },
  parentContainerLessPadding: {
    padding: "30px 100px 0px 100px",
  },
  mobileParentContainer: {
    padding: "20px 10px",
  },
};

export default NavBar;
