import React, { useEffect, useRef, useState } from "react";
import {
    Card,
    Image,
    Spinner,
    Button,
} from "react-bootstrap";
import * as authActions from "Redux/Actions/authActions";
import moment from "moment";
import { movie_placeholder } from "assets";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { notify } from "Utilities/Notifications/Notify";
import axios from "axios";
import log from "loglevel";

const MAX_RETRY_ATTEMPTS = 5;

const SeatSvg = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.63636 20C3.37879 20 3.16303 19.8933 2.98909 19.68C2.81515 19.4667 2.72788 19.203 2.72727 18.8889V17.7778C1.9697 17.7778 1.32576 17.4537 0.795454 16.8056C0.265152 16.1574 0 15.3704 0 14.4444V8.88889C0 8.27778 0.178182 7.75481 0.534545 7.32C0.890909 6.88518 1.31879 6.66741 1.81818 6.66667C2.31818 6.66667 2.74636 6.88444 3.10273 7.32C3.45909 7.75555 3.63697 8.27852 3.63636 8.88889V13.3333H16.3636V8.88889C16.3636 8.27778 16.5418 7.75481 16.8982 7.32C17.2545 6.88518 17.6824 6.66741 18.1818 6.66667C18.6818 6.66667 19.11 6.88444 19.4664 7.32C19.8227 7.75555 20.0006 8.27852 20 8.88889V14.4444C20 15.3704 19.7348 16.1574 19.2045 16.8056C18.6742 17.4537 18.0303 17.7778 17.2727 17.7778V18.8889C17.2727 19.2037 17.1855 19.4678 17.0109 19.6811C16.8364 19.8944 16.6206 20.0007 16.3636 20C16.1061 20 15.8903 19.8933 15.7164 19.68C15.5424 19.4667 15.4551 19.203 15.4545 18.8889V17.7778H4.54545V18.8889C4.54545 19.2037 4.45818 19.4678 4.28364 19.6811C4.10909 19.8944 3.89333 20.0007 3.63636 20ZM5.45454 11.1111V8.88889C5.45454 7.87037 5.20091 6.95815 4.69364 6.15222C4.18636 5.3463 3.53091 4.77704 2.72727 4.44444V3.33333C2.72727 2.40741 2.99242 1.62037 3.52273 0.972222C4.05303 0.324074 4.69697 0 5.45454 0H14.5455C15.303 0 15.947 0.324074 16.4773 0.972222C17.0076 1.62037 17.2727 2.40741 17.2727 3.33333V4.44444C16.4545 4.7037 15.7955 5.24556 15.2955 6.07C14.7955 6.89444 14.5455 7.83407 14.5455 8.88889V11.1111H5.45454Z"
                fill="red"
            />
        </svg>
    )
}

const OrderDetails = ({ order }) => {
    const navigate = useNavigate();
    const [downloading, setDownloading] = useState(false);
    const [allowDownload, setAllowDownload] = useState(true);

    const [orderData, setOrderData] = useState();
    const [paymentData, setPaymentData] = useState(null);
    const [theatreData, setTheatreData] = useState(null);
    const [showData, setShowData] = useState(null);
    const [updateMessage, setUpdateMessage] = useState("");

    const medium = useMediaQuery({ query: "(min-width: 768px)" });

    const [orderManagementApiLoading, setOrderManagementApiLoading] = useState(true);
    const attempts = useRef(0);

    const downloadTicket = async () => {
        const response = await authActions.getTicketPdf(orderData.id)
        if (response) {
            setDownloading(false)
        } else {
            setDownloading(false)
        }
    }

    useEffect(() => {
        setOrderData(order);
        setPaymentData(order?.payments?.[0]);
        setTheatreData(order?.theatre_data);
        setShowData(order?.show_data);
    }, [order]);

    useEffect(() => {
        if (showData && paymentData) {
            const today = new Date()
            const isOrderDatePassed = moment(today).format("YYYY MM DD") > moment(showData?.date).format("YYYY MM DD");
            const payment_status = paymentData?.payment_status;
            log.log({ isOrderDatePassed, payment_status, allowDownload: isOrderDatePassed || payment_status !== "PAID" });
            if (isOrderDatePassed || payment_status !== "PAID") {
                setAllowDownload(false);
            } else {
                setAllowDownload(true);
            }
        }
    }, [showData, paymentData]);

    useEffect(() => {

        const fetchOrder = async (id) => {
            try {
                const response = await authActions.getIndividualTicketOrder(id);
                log.log({response});
                setOrderData(response);
                setPaymentData(response?.payments?.[0]);
                setTheatreData(response?.theatre_data);
                setShowData(response?.show_data);
            } catch (err) {
                console.error(err);
                // notify({heading: "Error occurred while fetching the latest status", description: "Please reload this page to try again"}, "error");
                window.location.reload();
            }
        }

        const processMipsOrderManagementApiResponse = async (data) => {
            try {
                // const updatedOrderData = data?.updated_data?.order;
                // const payment_status = updatedOrderData?.payments?.[0]?.payment_status;
                // log.log({ updatedOrderData, payment_status, a: attempts.current })
                // if (updatedOrderData) {
                //     setOrderData(updatedOrderData);
                //     setPaymentData(updatedOrderData?.payments?.[0]);
                // }

                const payment_status_updated = data?.payment_status_updated;
                const order_status = data?.order_status;

                if (payment_status_updated && order_status === "SUCCESS") {
                    await fetchOrder(order?.id);
                } else if (payment_status_updated && order_status === "FAIL") {
                    // do nothing
                } else {
                    attempts.current += 1;
                    if (attempts.current < MAX_RETRY_ATTEMPTS) {
                        await mipsOrderManagementApi(paymentData?.id, orderData?.theatre);
                    }
                }
            } catch (err) {
                console.error(err);
                notify("Something went wrong while processing the response", "error");
            }
        }

        const mipsOrderManagementApi = async (payment_id, theatre_id) => {
            try {
                setOrderManagementApiLoading(true);
                setUpdateMessage("please, wait while we verify your payment status with the bank.")
                const url = "/order/rest/payments/verify_payment_status/";
                const payload = {
                    "order_id": orderData?.id,
                    "payment_id": payment_id,
                    "theatre_id": theatre_id
                }
                const response = await axios.post(url, payload);
                await processMipsOrderManagementApiResponse(response.data)
            } catch (e) {
                console.error(e);
                notify(e.message, "error");
            } finally {
                setOrderManagementApiLoading(false);
            }
        }

        if (orderData && paymentData) {
            const payment_gateway = paymentData?.payment_gateway;
            if (payment_gateway === "MIPS") {
                const payment_status = paymentData?.payment_status;
                if ((!payment_status || (payment_status !== "PAID" && payment_status !== 'FAILED'))) {
                    mipsOrderManagementApi(paymentData?.id, orderData?.theatre);
                } else {
                    setOrderManagementApiLoading(false);
                }
            } else {
                setOrderManagementApiLoading(false);
            }
        }
    }, [orderData, paymentData, order?.id]);


    return (
        <div className={`${medium ? "my-4" : "my-1"}`}>
            {orderManagementApiLoading ?
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <Spinner />
                    <p>{updateMessage}</p>
                </div>
                :
                <>
                    <Card
                        className="py-3 px-4 text-white d-flex flex-column bg-slate"
                        style={{
                            borderRadius: "10px 10px 0px 0px",
                        }}
                    >
                        <div>
                            <div className="text-center">
                                <h4 className="my-3" style={{
                                    color: paymentData?.payment_status === "PAID" ? "#4caf50" : paymentData?.payment_status === "FAILED" ? "red" : "yellow"
                                }}>
                                    Your order {paymentData?.payment_status === "PAID" ? "is confirmed!" : paymentData?.payment_status === "FAILED" ? "has failed!" : "is pending." }
                                </h4>
                                <Image
                                    src={showData?.client_program_data?.poster_image}
                                    style={{ maxWidth: 200, maxHeight: 250 }}
                                    rounded
                                    onClick={() => navigate(`${orderData?.id}`)}
                                    onError={e => e.target.src = movie_placeholder}
                                />
                                <h3 className="my-3">{showData?.client_program_data?.title}</h3>
                                <p>{theatreData?.title} {theatreData?.title && ","} <b>{showData?.hall_data?.hall_no}</b></p>
                            </div>
                            <div>{theatreData?.address}</div>
                            <div>
                                <span>{showData?.start_time}</span>
                                <span> | </span>
                                <span>{moment(showData?.date).format("DD MMM YYYY")}</span>
                            </div>
                            <h5>
                                <span><SeatSvg /></span>
                                <span> {orderData?.seats?.[0]?.seat_type_title} - </span>
                                <span>
                                    {orderData?.seats?.map((seat, i) => {
                                        return (
                                            <span key={i}>{seat?.seat_label} {i < orderData?.seats.length - 1 ? ", " : ""}</span>
                                        )
                                    })}
                                </span>
                            </h5>
                            <div className="d-flex justify-content-between">
                                <div>Ticket price</div>
                                <div>MUR. {orderData?.subtotal}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Discount</div>
                                <div>- MUR. {orderData?.discount || `0.00`}</div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>Tax</div>
                                <div>MUR. {orderData?.tax || `0.00`}</div>
                            </div>
                            {/* <hr style={{ border: "1px dashed" }} /> */}
                            <hr />
                            <div className="d-flex justify-content-between">
                                <div>TOTAL AMOUNT</div>
                                <h5>MUR. {orderData?.payments?.sort((p, q) => p.updated_at > q.updated_at ? -1 : 1)?.[0]?.total}</h5>
                            </div>
                        </div>
                        {orderData?.status === "BOOKED" && paymentData?.payment_status === "PAID" ?
                            <div className="d-flex justify-content-center align-items-center">
                                <Button
                                    className="bg-primary border-primary my-2 py-2"
                                    type="submit"
                                    disabled={downloading || !allowDownload}
                                    onClick={() => {
                                        if (allowDownload) {
                                            setDownloading(true);
                                            downloadTicket();
                                        }
                                    }}
                                >
                                    {downloading ? (
                                        <span>
                                            <Spinner size="sm" className="me-2" />
                                            Downloading ....
                                        </span>
                                    ) : (
                                        <span className="poppins">Download Ticket</span>
                                    )}
                                </Button>
                            </div>
                            :
                            <></>
                        }
                    </Card>
                    <Card
                        className={`${medium ? "" : "mb-5"} mt-1 py-3 px-4 text-white d-flex flex-column bg-slate`}
                        style={{
                            // borderRadius: "10px",
                            borderRadius: "0px 0px 10px 10px",
                            // border: "1px solid #9a9a9a"
                        }}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-grey subtitle">Booking Date & Time</div>
                                <p className="m-0">{moment(orderData?.created_at).format("DD MMM YYYY hh:mm a")}</p>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-grey subtitle">Payment Mode</div>
                                <p className="m-0">{paymentData?.payment_method}
                                    {
                                        paymentData?.payment_gateway === "MIPS"
                                            ? <span>&nbsp;(MIPS)</span>
                                            : paymentData?.payment_gateway === "MYT"
                                                ? <span>&nbsp;(my.t money)</span>
                                                : <></>
                                    }
                                </p>
                            </div>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div className="text-grey subtitle">Payment Status</div>
                                <p className="m-0">{paymentData?.payment_status}</p>
                            </div>
                        </div>
                    </Card>
                </>
            }
        </div>
    )
}

export default OrderDetails;
